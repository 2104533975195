import React from 'react'
import { NavLink, Route, Switch } from 'react-router-dom'
import classNames from 'classnames'
import queryString from 'query-string'
import { Header, Modal, Text, Button, Link } from '@pet-notify/ui'

import Columns from 'components/Layout/Columns'
import Column from 'components/Layout/Column'
import NewModal from 'sharedComponents/Modal'
import { formatFullDate } from 'lib/format-date'
import RowElement, * as Row from 'UserApp/components/Row'
import Layout from 'UserApp/components/Layout'
import UpgradeToPremium from 'UserApp/components/UpgradeToPremium'
import UpgradeToPremiumSuccess from 'UserApp/components/UpgradeToPremium/Success'
import UpdateSubscription from './UpdateSubscription'
import DowngradeSubscriptionForm from './DowngradeSubscriptionForm'
import CancelSubscriptionForm from './CancelSubscriptionForm'
import PlanForm from './PlanForm'
import PaymentForm from './PaymentForm'
import ChangeNameForm from './ChangeNameForm'
import ChangeEmailForm from './ChangeEmailForm'
import ChangePasswordForm from './ChangePasswordForm'
import DeleteAccountForm from './DeleteAccountForm'
import ReactivateSubscriptionForm from './ReactivateSubscriptionForm'
import useUser from 'UserApp/hooks/useUser'

import style from './style.module.scss'

function SettingsScreen({ location, history }) {
  const { user } = useUser()
  const { card, subscription, hasSubscription, petCount, status, subscriptionInterval } = user

  const { mobile } = queryString.parse(location.search)

  function handleClose() {
    history.push('/settings')
  }

  const hidePlanText = hasSubscription && card && subscription.isCanceled
  // console.log(`Last date of billing :: `,subscription.lastDayOfBillingPeriod)

  return (
    <Layout columns title='More'>
      <Columns>
        <Column>
          <Header className={style.header} size='small' as='h3'>
            Account
          </Header>
          <RowElement>
            <Row.Title>Name</Row.Title>
            <Row.Value>{user.name}</Row.Value>
            <Row.Button className={style.changeNameLink}>
              <Link as={NavLink} to='/settings/change-name'>
                Edit
              </Link>
            </Row.Button>
          </RowElement>
          <RowElement>
            <Row.Title>Email</Row.Title>
            <Row.Button>
              <Link as={NavLink} to='/settings/change-email'>
                Edit
              </Link>
            </Row.Button>
          </RowElement>
          <RowElement>
            <Row.Title>Address &amp; Phone Number</Row.Title>
            <Row.Button>
              <Link as={NavLink} to='/address/edit'>
                Edit
              </Link>
            </Row.Button>
          </RowElement>
          <RowElement>
            <Row.Title>Password</Row.Title>
            <Row.Button>
              <Link as={NavLink} to='/settings/change-password'>
                Change Password
              </Link>
            </Row.Button>
          </RowElement>
          <RowElement>
            <Row.Title>
              <Link as={NavLink} to='/signout'>
                Sign out
              </Link>
            </Row.Title>
          </RowElement>

          <Header
            className={classNames(style.header, style.billing)}
            size='small'
            as='h3'
          >
            Subscription information
          </Header>

          {!hidePlanText && (
            <Text className={style.billingStatus}>
              You’re currently on the{' '}
              <strong>
                {hasSubscription && !subscription.isCanceled
                  ? 'premium'
                  : 'free'}
              </strong>{' '}
              plan.
              {!hasSubscription &&
                petCount === 0 &&
                ' You must add a pet to your Pet Vault before upgrading to premium.'}
            </Text>
          )}

          {!hasSubscription && petCount > 0 && (
            <Button as={NavLink} to='/settings/upgrade'>
              Upgrade to premium
            </Button>
          )}

          {hasSubscription && (
            <>
              {subscription.isCanceled ? (
                <>
                  <Text mb='4'>
                    You’ve downgraded to the <strong>free</strong> plan. Your
                    premium plan is still active until{' '}
                    <strong>
                      {formatFullDate(subscription.lastDayOfBillingPeriod)}
                    </strong>
                    .
                  </Text>

                  <ReactivateSubscriptionForm />
                </>
              ) : (
                <>
                  <RowElement className={style.billingItems}>
                    <Row.Title>Payment method</Row.Title>
                    <Row.Value>
                      {card ? (
                        <>
                          {card.brand} ending in {card.last4}
                        </>
                      ) : (
                        'not yet set'
                      )}
                    </Row.Value>
                    <Row.Button className={style.editLink}>
                      <Link as={NavLink} to='/settings/payment'>
                        Edit
                      </Link>
                    </Row.Button>
                  </RowElement>

                  <RowElement className={style.billingItems}>
                    <Row.Title>Billing period</Row.Title>
                    <Row.Value>
                      {subscription.billingPeriod === 'year'
                        ? 'Annual'
                        : 'Monthly'}
                    </Row.Value>
                    <Row.Button className={style.editLink}>
                      <Link as={NavLink} to='/settings/plan'>
                        Edit
                      </Link>
                    </Row.Button>
                  </RowElement>

                  <RowElement className={style.billingItems}>
                    {subscription.status === 'past_due' ? (
                      <>
                        <Row.Title>
                          Past due since{' '}
                          {formatFullDate(subscription.currentPeriodStart)}
                        </Row.Title>
                        <Row.Button className={style.editLink}>
                          <Link as={NavLink} to='/settings/payment'>
                            Update Payment
                          </Link>
                        </Row.Button>
                      </>
                    ) : (
                      <>
                        <Row.Title>Next payment due</Row.Title>
                        <Row.Value>
                          {formatFullDate(subscription.lastDayOfBillingPeriod)}
                        </Row.Value>
                      </>
                    )}
                  </RowElement>
                  <RowElement>
                    <Row.Title>
                      <Link
                        as={NavLink}
                        to='/settings/downgrade-subscription'
                        variant='cancel'
                      >
                        Downgrade to free account
                      </Link>
                    </Row.Title>
                  </RowElement>
                </>
              )}
            </>
          )}

          {hasSubscription && !subscription.isCanceled && (
            <RowElement>
              <Row.Title>
                <Link
                  as={NavLink}
                  to='/settings/cancel-subscription'
                  variant='cancel'
                >
                  Cancel subscription
                </Link>
              </Row.Title>
            </RowElement>
          )}

          {(!hasSubscription || subscription.isCanceled) && (
            <RowElement>
              <Row.Title>
                <Link
                  as={NavLink}
                  to='/settings/delete-account'
                  variant='cancel'
                >
                  Delete Account
                </Link>
              </Row.Title>
            </RowElement>
          )}
        </Column>

        <Column>
          <Header className={style.header} size='small' as='h3'>
            Additional information
          </Header>

          <RowElement>
            <Row.Title>
              <Link href='https://www.petnotify.com/faq'>
                Frequently asked questions
              </Link>
            </Row.Title>
          </RowElement>

          <RowElement>
            <Row.Title>
              <Link href='https://www.petnotify.com/become-a-partner'>
                Partnership information
              </Link>
            </Row.Title>
          </RowElement>

          <RowElement>
            <Row.Title>
              <Link href='mailto:support@petnotify.com?subject=Product Feedback'>
                Provide feedback
              </Link>
            </Row.Title>
          </RowElement>

          <RowElement>
            <Row.Title>
              <Link href='https://www.petnotify.com/terms-of-use'>
                Terms of use
              </Link>
            </Row.Title>
          </RowElement>

          <RowElement>
            <Row.Title>
              <Link href='https://www.petnotify.com/privacy-policy'>
                Privacy policy
              </Link>
            </Row.Title>
          </RowElement>
        </Column>
      </Columns>

      <Switch>
        <Route
          path='/settings/upgrade/success'
          render={() => (
            <NewModal onClose={handleClose} className={style.successModal}>
              <UpgradeToPremiumSuccess onClose={handleClose} />
            </NewModal>
          )}
        />
        <Route
          path='/settings/upgrade'
          render={() => (
            <NewModal
              onClose={handleClose}
              className={classNames(style.upgradeToPremiumModal, {
                [style.isFullscreen]: mobile === 'true',
              })}
              isFullscreen={mobile === 'true'}
            >
              <UpgradeToPremium onClose={handleClose} petCount={petCount} />
            </NewModal>
          )}
        />
        <Route
          path='/settings/update-subscription'
          render={() => (
            <Modal onClose={handleClose} closeOnOverlayClick={false}>
              <UpdateSubscription />
            </Modal>
          )}
        />
        <Route
          path='/settings/change-name'
          render={() => (
            <Modal onClose={handleClose}>
              <ChangeNameForm onClose={handleClose} />
            </Modal>
          )}
        />
        <Route
          path='/settings/change-email'
          render={() => (
            <Modal onClose={handleClose}>
              <ChangeEmailForm onClose={handleClose} />
            </Modal>
          )}
        />
        <Route
          path='/settings/change-password'
          render={() => (
            <Modal onClose={handleClose}>
              <ChangePasswordForm onClose={handleClose} />
            </Modal>
          )}
        />
        <Route
          path='/settings/downgrade-subscription'
          render={() => (
            <Modal onClose={handleClose}>
              <DowngradeSubscriptionForm onClose={handleClose} />
            </Modal>
          )}
        />
        <Route
          path='/settings/cancel-subscription'
          render={() => (
            <Modal onClose={handleClose}>
              <CancelSubscriptionForm onClose={handleClose} />
            </Modal>
          )}
        />
        <Route
          path='/settings/delete-account'
          render={() => (
            <Modal onClose={handleClose}>
              <DeleteAccountForm onClose={handleClose} />
            </Modal>
          )}
        />
        <Route
          path='/settings/payment'
          render={() => (
            <Modal onClose={handleClose}>
              <PaymentForm userStatus={status} subscriptionInterval={subscriptionInterval} onSuccess={handleClose} />
            </Modal>
          )}
        />
        <Route
          path='/settings/plan'
          render={() => (
            <Modal onClose={handleClose}>
              <PlanForm onSuccess={handleClose} />
            </Modal>
          )}
        />
      </Switch>
    </Layout>
  )
}

export default SettingsScreen

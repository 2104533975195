import React from 'react'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js'
import { Redirect } from 'react-router-dom'
import numeral from 'numeral'

import INTELLITAG_SIZE_OPTIONS from 'lib/intellitag-size-options'
import Loader from 'components/Loader'
import {
  Form,
  Label,
  StripeInput,
  FieldGroup,
  Button,
  ErrorText,
  Checkbox,
} from 'components/Forms'
import Input from 'sharedComponents/Input'
import Link from 'sharedComponents/Link'
import HeaderOne from 'components/Headers/HeaderOne'
import HeaderThree from 'components/Headers/HeaderThree'
import Text from 'components/Text'
import PaymentMethodIcons from 'UserApp/components/PaymentMethodIcons'
import AddressFormFields from '../AddressFormFields'
import PromoCode from '../../../components/InlinePromoCode'
import mixPanel, { EVENTS } from 'services/mix-panel'
import style from './style.module.scss'

function Payment(props) {
  const {
    isPaymentRequired,
    paymentRequest,
    breakdown,
    onSubmit,
    isProcessing,
    isLoading,
    wasSuccessful,
    shippingAddress,
    isMobile,
  } = props

  if (wasSuccessful) {
    return (
      <Redirect
        to={
          isMobile
            ? '/order-intellitag/success?mobile=true'
            : '/order-intellitag/success'
        }
        push
      />
    )
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={style.wrapper}>
      <HeaderOne className={style.header}>Order details</HeaderOne>
      <div className={style.activeSubscription}>
        You have an active premium subscription
      </div>
      {breakdown && (
        <div className={style.orderSection}>
          {breakdown.intellitags.map((intellitag) => (
            <dl className={style.orderLineItem} key={intellitag.nameOnTag}>
              <dt className={style.orderItem}>
                <p className={style.orderItemTitle}>Intellitag</p>
                <p className={style.orderItemDescription}>
                  Size:{' '}
                  {
                    INTELLITAG_SIZE_OPTIONS.find(
                      (option) => option.value === intellitag.size,
                    ).label
                  }
                  <br />
                  Name: {intellitag.nameOnTag}
                  {intellitag.phone && (
                    <React.Fragment>
                      <br />
                      Phone: {intellitag.phone}
                    </React.Fragment>
                  )}
                </p>
              </dt>
              <dd className={style.orderItemPrice}>
                <Link to='/order-intellitag' className={style.link}>
                  Edit order
                </Link>
                Included in subscription
              </dd>
            </dl>
          ))}

          {breakdown.items.map((item) => (
            <dl className={style.orderLineItem} key={item.label}>
              <dt className={style.orderItem}>{item.label}</dt>
              <dd className={style.orderItemPrice}>
                {numeral(item.cost).format('$0.00')}
              </dd>
            </dl>
          ))}
        </div>
      )}

      {isPaymentRequired && <PromoCode />}

      {isPaymentRequired && paymentRequest && (
        <div className={style.paymentButtons}>
          <PaymentRequestButtonElement paymentRequest={paymentRequest} />
        </div>
      )}

      <Form onSubmit={onSubmit}>
        {({ onChange, values, error }) => (
          <React.Fragment>
            {isPaymentRequired && (
              <>
                <Label>
                  Credit card number
                  <StripeInput
                    component={CardNumberElement}
                    onChange={onChange}
                  />
                </Label>

                <FieldGroup>
                  <div>
                    <Label>
                      Expiration (mm/yy)
                      <StripeInput
                        component={CardExpiryElement}
                        onChange={onChange}
                      />
                    </Label>
                  </div>
                  <div>
                    <Label>
                      Security code (CVV)
                      <StripeInput
                        component={CardCvcElement}
                        onChange={onChange}
                      />
                    </Label>
                  </div>
                </FieldGroup>

                <div className={style.zipCodeField}>
                  <Label htmlFor='zip'>Billing zip code</Label>
                  <Input
                    name='zip'
                    value={values.zip}
                    onChange={onChange}
                    id='zip'
                  />
                </div>

                <PaymentMethodIcons />
              </>
            )}

            {error && <ErrorText>{error}</ErrorText>}

            {isProcessing ? (
              <Loader />
            ) : (
              <Button
                type='submit'
                className={style.button}
                onClick={() => mixPanel.track(EVENTS.CONFIRM_PAYMENT_CLICK)}
              >
                Confirm order
              </Button>
            )}
          </React.Fragment>
        )}
      </Form>
    </div>
  )
}

export default Payment

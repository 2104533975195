import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import numeral from 'numeral'

import api from 'services/pet-notify-api'
import { completeSignup } from 'UserApp/store/signup/actions'
import SignupPaymentScreen from 'UserApp/screens/Signup/Premium/Payment'

// SignupPaymentScreenContainer.propTypes = {
//   order: PropTypes.object,
//   createOrder: PropTypes.func,
//   stripe: PropTypes.shape({
//     createToken: PropTypes.func,
//     paymentRequest: PropTypes.func,
//   }),
// }

function SignupPaymentScreenContainer(props) {
  const { history, affiliate, completeSignup, signup, promoCode } = props
  const redemptionCode = localStorage.getItem('redemptionCode')
  const stripe = useStripe()
  const elements = useElements()
  const [isLoading, setIsLoading] = useState(true)
  const [isProcessing, setIsProcessing] = useState(false)
  const [breakdown, setBreakdown] = useState()
  const [paymentRequest, setPaymentRequest] = useState(null)
  const [subscriptionPlan, setSubscriptionPlan] = useState(
    signup.plan || 'month',
  )

  function handleChangeSubscriptionPlan(plan) {
    setSubscriptionPlan(plan)
  }

  // useEffect(() => {
  //   if (breakdown) {
  //     const amount = breakdown.order.total
  //     const label = `Intellitag(s) & ${numeral(
  //       breakdown.subscriptionPlans[subscriptionPlan].amount,
  //     ).format('$0.00')}}`

  //     const pr = stripe.paymentRequest({
  //       country: 'US',
  //       currency: 'usd',
  //       total: {
  //         label,
  //         amount,
  //       },
  //     })

  //     pr.on('token', async ({ complete, token }) => {
  //       setIsProcessing(true)

  //       try {
  //         await completeSignup({
  //           token: token.id,
  //           promoCode,
  //           isAnnualPlan: subscriptionPlan === 'year',
  //           stripeSubscriptionPlan:
  //             breakdown.subscriptionPlans[subscriptionPlan].id,
  //         })
  //         setIsProcessing(false)
  //       } catch (e) {
  //         setIsProcessing(false)
  //         throw e
  //       }

  //       complete('success')
  //       history.push('/signup/success?hasIntellitag=true')
  //     })

  //     pr.canMakePayment().then((result) => {
  //       if (result) {
  //         setPaymentRequest(pr)
  //       }
  //     })

  //     return
  //   }
  // }, [breakdown, promoCode])

  useEffect(() => {
    if (!signup.user) {
      history.push('/signup')
      return
    }

    if (signup.intellitags) {
      setIsLoading(true)
      api.signup
        .breakdown({ ...signup, promoCode, subscriptionPlan })
        .then((result) => {
          setBreakdown(result)
          setIsLoading(false)
        })
    }
  }, [promoCode, subscriptionPlan])

  async function handleSubmit(data) {
    const { zip, policyAcceptance } = data

    if (!zip || zip === '') {
      throw new Error('Billing ZIP code required')
    }

    if (!policyAcceptance) {
      throw new Error('Please verify you understand the User Agreement and Privacy Policy')
    }

    setIsProcessing(true)

    const cardNumberElement = elements.getElement(CardNumberElement)

    const { token, error } = await stripe.createToken(cardNumberElement, {
      address_zip: zip,
    })

    if (error) {
      setIsProcessing(false)
      throw error.message
    }

    try {
      await completeSignup({
        token: token.id,
        promoCode,
        redemptionCode,
        isAnnualPlan: subscriptionPlan === 'year',
        stripeSubscriptionPlan:
          breakdown.subscriptionPlans[subscriptionPlan].id,
        policyAcceptance,
      })
      setIsProcessing(false)
    } catch (e) {
      setIsProcessing(false)
      throw e
    }

    history.push('/signup/success?hasIntellitag=true')
  }

  return (
    <SignupPaymentScreen
      onSubmit={handleSubmit}
      isProcessing={isProcessing}
      isLoading={isLoading}
      breakdown={breakdown}
      signup={signup}
      affiliate={affiliate}
      subscriptionPlan={subscriptionPlan}
      onChangeSubscriptionPlan={handleChangeSubscriptionPlan}
      paymentRequest={paymentRequest}
    />
  )
}

export default connect(
  (state) => ({
    signup: state.signup,
    promoCode: state.promoCode && state.promoCode.code,
    affiliate: state.promoCode && state.promoCode.affiliate,
  }),
  { completeSignup },
)(SignupPaymentScreenContainer)
